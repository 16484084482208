<template>
  <div class="races-header">
    <GamesHeader
      :showBackButton="!isIntegration"
      :showInfoButton="isMobile"
      :showBalance="showBalance"
      @ShowInfo="$emit('ShowInfo')"
      @ShowSettings="$emit('ShowSettings')">
    </GamesHeader>
    <Boost v-if="isBoostActive && isMobile" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GamesHeader } from '@nsftx/games-sdk-js';
import Boost from '@/components/Boost';

export default {
  name: 'Header',
  components: {
    GamesHeader,
    Boost,
  },
  computed: {
    ...mapGetters([
      'isBoostActive',
      'isMobile',
      'config',
    ]),
    isIntegration() {
      if (this.config.ui.config.mode
        && (this.config.ui.config.mode === 'integration' || this.config.ui.config.mode === 'standalone')) {
        return true;
      }
      return false;
    },
    showBalance() {
      if ('showBalance' in this.config.ui.config) {
        return this.config.ui.config.showBalance;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.races-header {
  display: flex;
  flex-direction: column;
}
</style>
